import * as actionTypes from '../actions/actionTypes'

const initialState = { data_stats: null }

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_TABLE_MODE_STATS_SUCCESS:
      return { ...state, data_stats: action.data_stats }
    case actionTypes.LOAD_TABLE_MODE_STATS_FAILURE:
      return { ...state, data_stats: null }
    case actionTypes.REMOVE_LOADED_TABLE_MODE_STATS:
      return { ...state, data_stats: null }
    default:
      return state
  }
}

export default reducer
