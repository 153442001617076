import * as actionTypes from '../actions/actionTypes'

const initialState = { selectedPage: 'reports' }

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_PAGE:
      return { ...state, selectedPage: action.payload }
    default:
      return state
  }
}
export default reducer
