import React from 'react'

import { NavItem, NavLink } from 'reactstrap'
import { Link } from 'react-router-dom'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
  'Reports': { defaultMessage: 'Reports', id: 'Ppx673' },
  'Report': { defaultMessage: 'Report', id: 'x5Tz6M' },
  'Dashboard': { defaultMessage: 'Dashboard', id: 'hzSNj4' },
  'Employees': { defaultMessage: 'Employees', id: 'HHzdGS' },
  'Projects': { defaultMessage: 'Projects', id: 'UxTJRa' },
  'Managers': { defaultMessage: 'Managers', id: 'i4AQpn' },
  'Table Mode Stats': { defaultMessage: 'Table Mode Stats', id: 'GlvxEe' },
  'Jira Worklog Stats': { defaultMessage: 'Jira Worklog Stats', id: 'bLnpoe' },
  'Milestones': { defaultMessage: 'Milestones', id: 'xpcHBZ' },
  'Settings': { defaultMessage: 'Settings', id: 'D3idYv' },
  'Billable Hours': { defaultMessage: 'Billable Hours', id: 'ze1VEd' },
  'Billing Periods': { defaultMessage: 'Billing Periods', id: 'mGsvdx' }
})

export const RenderLink = (props) => {
  const intl = useIntl()

  return (
    <NavItem>
      {props.permissionLink &&
        <NavLink
          tag={Link}
          active={props.isUrlSelected && props.isUrlSelected(props.permissionLink.link)}
          to={`/${props.permissionLink.link}`}
          onClick={() => props.handleLinkClick && props.handleLinkClick(props.permissionLink)}
          className="header-visible-link"
        >
          {intl.formatMessage(messages[props.permissionLink.label])}
        </NavLink>
      }
    </NavItem>
  )
}
