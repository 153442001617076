export const setFormControlConfig = (login, errorText, endAdornment, showPassword) => {
  return [
    {
      formControl: '',
      inputLabel: {
        htmlFor: 'name-simple',
        text: 'Username'
      },
      input: {
        id: 'name-simple',
        type: '',
        value: login.username,
        form: 'username',
        endAdornment: ''
      },
      test: { id: 'usernameTextfield' }
    },
    {
      formControl: 'name-helper-text',
      inputLabel: {
        htmlFor: 'name-helper',
        text: 'Password'
      },
      input: {
        id: '',
        type: showPassword,
        value: login.password,
        form: 'password',
        endAdornment: endAdornment
      },
      test: { id: 'passwordTextfield' }
    }
  ]
}
