const host = `${location.protocol}//${location.host}`

export const getUrlSelectedId = (path) => {
  const idRegExp = new RegExp(`^${host}/reports/(?:for_employee|for_project)/([0-9]+)(?=(?:/|$))`)
  const result = idRegExp.exec(path)
  return result === null ? null : result[1]
}

export const isUrlForEmployee = (path) => {
  const idRegExp = new RegExp(`^${host}/reports/(for_employee|for_project)/(?:[0-9]+)(?=(?:/|$))`)
  const result = idRegExp.exec(path)
  const forEmployee = result === null ? null : result[1]
  return !/for_project/.test(forEmployee)
}

export const forEmployeeRegExp = new RegExp(`^${host}/reports/for_employee(?:/|$)`)
export const forProjectRegExp = new RegExp(`^${host}/reports/for_project(?:/|$)`)
export const idRegExp = new RegExp(`^${host}/reports/(?:for_employee|for_project)/([0-9]+)(?:/|$)`)
export const idRegExpWithoutHost = new RegExp(`/reports/(?:for_employee|for_project)/([0-9]+)(?:/|$)`)
export const noIdRegExp = new RegExp(`^${host}/reports/(?:for_employee|for_project)/-(?:/|$)`)
export const forEmployeeExactRegExp = new RegExp(`^${host}/reports/for_employee(?:/|)$`)

export const yearAndMonthRegExp = new RegExp(
  `^${host}/reports/(?:for_employee|for_project)/(?:[0-9]+|-)/([0-9]{4}-[0-9]{2})(?:/|$)`
)
export const dateRegExp = new RegExp(
  `^${host}/reports/(?:for_employee|for_project)/(?:[0-9]+|-)/([0-9]{4}-[0-9]{2})/([0-9]{2})(?:/|$)`
)

export const reportIdRegExp = new RegExp(
  `^${host}/reports/(?:for_employee|for_project)/(?:[0-9]+|-)/[0-9]{4}-[0-9]{2}-[0-9]{2}/reports_per_day/([0-9]+)/?$`
)

export const idExactRegExp = new RegExp(`^${host}/reports/(?:for_employee|for_project)(?:/[0-9]+|)/?$`)
export const pageRegExp = new RegExp(`^${host}/([a-z-]+)(?:/|$)`)
export const pageFromLinkRegExp = new RegExp(`^([a-z-]+)(?:/|$)`)

export const reportPerDayRegExp = new RegExp(
  `^${host}/reports/(?:for_employee|for_project)/(?:[0-9]+|-)/[0-9]{4}-[0-9]{2}-[0-9]{2}/reports_per_day/?([0-9]+)?/?$`
)
