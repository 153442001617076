import * as actionTypes from '../actions/actionTypes'

const initialState = {
  selectedProject: null,
  selectedEmployee: null,
  forEmployee: true,
  hideInactive: true,
  calendarData: [],
  selectedEvents: [],
  selectedReports: [],
  statsProjects: [],
  statsEmployees: [],
  allReports: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_PROJECT:
      return { ...state, selectedProject: action.payload }
    case actionTypes.SET_SELECTED_EMPLOYEE:
      return { ...state, selectedEmployee: action.payload }
    case actionTypes.SET_NO_PROJECT_OPTION_MESSAGE:
      return { ...state, noProjectOptionMessage: action.payload }
    case actionTypes.SET_NO_EMPLOYEE_OPTION_MESSAGE:
      return { ...state, noEmployeeOptionMessage: action.payload }
    case actionTypes.IS_FOR_EMPLOYEE:
      return { ...state, forEmployee: action.payload }
    case actionTypes.IS_HIDING_INACTIVE:
      return { ...state, hideInactive: action.payload }
    case actionTypes.SET_CALENDAR_DATA:
      return { ...state, calendarData: action.payload }
    case actionTypes.CLEAR_CALENDAR_DATA:
      return { ...state, calendarData: [] }
    case actionTypes.SET_DATE:
      return { ...state, date: action.payload }
    case actionTypes.SET_SELECTED_EVENTS:
      return { ...state, selectedEvents: action.payload }
    case actionTypes.LOAD_STATS_REPORTS_SUCCESS:
      return { ...state, selectedReports: action.reports }
    case actionTypes.LOAD_STATS_REPORTS_FAILURE:
      return { ...state, selectedReports: [] }
    case actionTypes.LOAD_STATS_PROJECTS_SUCCESS:
      return { ...state, statsProjects: action.projects }
    case actionTypes.LOAD_STATS_PROJECTS_FOR_REQUESTS_SUCCESS:
      return { ...state, statsProjectsForRequests: action.projects }
    case actionTypes.LOAD_STATS_PROJECTS_FAILURE:
      return { ...state, statsProjects: null, projectsForRequests: null }
    case actionTypes.LOAD_STATS_EMPLOYEES_SUCCESS:
      return { ...state, statsEmployees: action.employees }
    case actionTypes.LOAD_STATS_EMPLOYEES_FAILURE:
      return { ...state, statsEmployees: null }
    case actionTypes.LOAD_STATS_EMPLOYEE_SUCCESS:
      return { ...state, statsEmployees: [action.payload], selectedEmployee: action.payload }
    case actionTypes.LOAD_STATS_EMPLOYEE_FAILURE:
      return { ...state, statsEmployees: [], selectedEmployee: null }
    case actionTypes.FETCH_INFO_FOR_ALL_REPORTS:
      return { ...state, allReports: [...state.allReports, action.allReports] }
    case actionTypes.CLEAR_ALL_REPORTS:
      return { ...state, allReports: [] }
    default:
      return state
  }
}
export default reducer
