import React from 'react'

export const useDebounce = (callback, delay) => {
  const timer = React.useRef(null)

  const debounceCallback = React.useCallback((...args) => {
    if (timer.current)
      clearTimeout(timer.current)

    timer.current = setTimeout(() => callback(...args), delay)
  }, [callback, delay])

  return debounceCallback
}