import React from 'react'

import { Box, CircularProgress } from '@mui/material'

export const Progress = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
      <CircularProgress />
    </Box>
  )
}