import * as actionTypes from '../actions/actionTypes'
import { tryParseJSONgroups } from '@utils/util'

const initialState = {
  groups: localStorage.getItem('groups') ? tryParseJSONgroups(localStorage.getItem('groups')) : null,
  userId: localStorage.getItem('userId') ? localStorage.getItem('userId') : null,
  error: null,
  errorCode: null,
  message: null,
  isFetching: false,
  isLoaded: false,
  snackbarOpen: false,
  language: 'EN',
  saveLocation: '/reports'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GLOBAL_LOAD_REQUEST:
      return { ...state, isFetching: true }
    case actionTypes.LOAD_PROFILE_SUCCESS:
      return { ...state, groups: JSON.parse(action.groups), userId: action.userId }
    case actionTypes.LOAD_PROFILE_FAILURE:
      return { ...state, groups: null, userId: null }
    case actionTypes.GLOBAL_LOAD_SUCCESS:
      return { ...state, isFetching: false, error: null, isLoaded: true }
    case actionTypes.GLOBAL_LOAD_FAILURE:
      return {
        ...state, isFetching: false, error: action.error, isLoaded: true, snackbarOpen: true, errorCode: action.errorCode
      }
    case actionTypes.GLOBAL_SAVE_SUCCESS:
      return {
        ...state, isFetching: false, error: null, message: action.message, isLoaded: true, snackbarOpen: true
      }
    case actionTypes.GLOBAL_SAVE_FAILURE:
      return { ...state, isFetching: false, error: action.error, isLoaded: true, snackbarOpen: true }
    case actionTypes.GLOBAL_SNACKBAR_OPEN:
      return { ...state, snackbarOpen: true }
    case actionTypes.GLOBAL_SNACKBAR_CLOSE:
      return { ...state, snackbarOpen: false }
    case actionTypes.GLOBAL_INPUT_FAILURE:
      return { ...state, error: action.error, snackbarOpen: true }
    case actionTypes.GLOBAL_SHOW_NOTIFICATION:
      return { ...state, message: action.message, snackbarOpen: true }
    case actionTypes.GLOBAL_COMMON_FAILURE:
      return { ...state, error: action.error, snackbarOpen: true }
    case actionTypes.GLOBAL_SET_LANGUAGE:
      return { ...state, language: action.language }
    case actionTypes.SAVE_LOCATION:
      return { ...state, saveLocation: action.path }
    default:
      return state
  }
}

export default reducer
