import React from 'react'

import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom'

import { Auth } from '@utils/helpers/auth'
import { tryParseJSONgroups } from '@utils/util'

import { Progress } from './components/Progress'
import { Header } from './header/Header'
const Stats = React.lazy(() => import('./stats/Stats'))
const Report = React.lazy(() => import('../report/Report'))
const Employees = React.lazy(() => import('./employees/Employees'))
const TableMode = React.lazy(() => import('./tableModeStats/TableMode'))
const WorklogStats = React.lazy(() => import('./jiraWorklogStats/WorklogStats'))
const EmployeeDashboard = React.lazy(() => import('./employees/EmployeeDashboard'))
const Projects = React.lazy(() => import('./projects/Projects'))
const UpdateUsernames = React.lazy(() => import('../ldap/UpdateUsernames'))
const SeniorManagersContainer = React.lazy(() => import('./seniorManagers/SeniorManagersContainer'))
const MilestonesMain = React.lazy(() => import('./milestones/MilestonesMain'))
const Settings = React.lazy(() => import('./settings/Settings'))
const BillableHours = React.lazy(() => import('./billableHours/BillableHours'))
const BillingPageWrapper = React.lazy(() => import('./billablePeriods/PageWrapper'))

import { useSelector } from 'react-redux'

export const Main = withRouter((props) => {
  const { groups } = useSelector((state) => state.global)

  const logout = () => Auth.logout(props.history)

  const showFor = (groupsShowFor) => {
    const userGroups = groups || tryParseJSONgroups(localStorage.getItem('groups'))
    return userGroups && userGroups.some((userGroup) => groupsShowFor.includes(userGroup))
  }

  return (
    <BrowserRouter>
      <div className="main-wrapper">
        <div className="header">
          <Header {...props} logout={logout} history={props.history} />
        </div>
        <div className="main-page">
          <React.Suspense fallback={<Progress />}>
            <Switch>
              {showFor(['admins', 'viewers', 'accountants', 'managers']) &&
                <Route path='/reports/:type?/:value?/:date?' component={Stats} />}
              {showFor(['viewers', 'managers', 'admins', 'headhunters', 'accountants']) &&
                <Route path='/report' component={Report} />}
              {showFor(['viewers']) && <Route path='/dashboard' component={EmployeeDashboard} />}
              {showFor(['admins', 'managers', 'headhunters']) && <Route path='/employees/:id?' component={Employees} />}
              {showFor(['admins', 'managers']) && <Route path='/projects/:id?' component={Projects} />}
              {showFor(['admins']) && <Route path='/managers' component={SeniorManagersContainer} />}
              {showFor(['admins']) && <Route path='/ldap-update' component={UpdateUsernames} />}
              {showFor(['admins', 'managers', 'accountants']) && <Route path='/tablemodestats/:id?' component={TableMode} />}
              {showFor(['admins', 'managers']) && <Route path='/jira-worklog-stats' component={WorklogStats} />}
              {showFor(['admins', 'managers']) && <Route path='/billable-hours/:type?' component={BillableHours} />}
              {showFor(['admins']) && <Route path='/milestones' component={MilestonesMain} />}
              {showFor(['admins']) && <Route path='/settings' component={Settings} />}
              {showFor(['admins', 'managers']) && <Route path='/billing-periods/:type?' component={BillingPageWrapper} />}
            </Switch>
          </React.Suspense>
        </div>
      </div>
    </BrowserRouter>
  )
})
