import * as actionTypes from '../actions/actionTypes'

const initialState = {
  jira_servers: [],
  worklog_projects: [],
  jira_projects: null,
  jira_employees_working_time: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_JIRA_EMPLOYEE_SERVERS_SUCCESS:
      return {
        ...state,
        jira_servers: action.jira_servers,
        jira_projects: action.jira_projects
      }
    case actionTypes.LOAD_JIRA_EMPLOYEE_SERVERS_FAILURE:
      return { ...state, jira_servers: null, jira_projects: null }
    case actionTypes.LOAD_JIRA_EMPLOYEES_WORKING_TIME_SUCCESS:
      return {
        ...state,
        jira_employees_working_time: action.jira_employees_working_time
      }
    case actionTypes.LOAD_JIRA_EMPLOYEES_WORKING_TIME_FAILURE:
      return { ...state, jira_employees_working_time: null }
    case actionTypes.SET_WORKLOG_PROJECTS:
      return { ...state, worklog_projects: action.projects }
    default:
      return state
  }
}

export default reducer
