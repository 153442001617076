import * as actionTypes from '../actions/actionTypes'
import { push } from 'object-path-immutable'

const initialState = {
  technologies: null,
  updatedTechnologies: null
}

const reducer = (state = initialState, action) => {
  let updatedState
  switch (action.type) {
    case actionTypes.LOAD_TECHNOLOGIES_SUCCESS:
      return {
        ...state,
        technologies: action.technologies,
        updatedTechnologies: action.technologies
      }
    case actionTypes.LOAD_TECHNOLOGIES_FAILURE:
      return { ...state, technologies: null, updatedTechnologies: null }
    case actionTypes.CREATE_TECHNOLOGY_SUCCESS:
      updatedState = push(state, `updatedTechnologies`, action.newTechnology)
      return {
        ...state,
        technologies: updatedState.updatedTechnologies,
        updatedTechnologies: updatedState.updatedTechnologies
      }
    case actionTypes.CREATE_TECHNOLOGY_FAILURE:
      return { ...state, updatedTechnologies: state.technologies }
    case actionTypes.DELETE_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        updatedTechnologies: state.technologies.filter(
          (tech) => tech.id !== action.deletedTechnologyId
        )
      }
    case actionTypes.DELETE_TECHNOLOGY_FAILURE:
      return { ...state, updatedTechnologies: state.technologies }
    default:
      return state
  }
}

export default reducer
