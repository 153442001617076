import * as actionTypes from '../actions/actionTypes'

const initialState = {
  reports: [],
  dates: [],
  projects: [],
  jiraServers: [],
  report: '',
  plan: '',
  isSaved: false,
  error_oauth: null,
  jiraServerAuthorized: false,
  oauth_status: true
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_REPORTS_INFO_SUCCESS:
      return { ...state, dates: action.dates, projects: action.projects, jiraServers: action.jiraServers }
    case actionTypes.LOAD_REPORTS_INFO_FAILURE:
      return { ...state, dates: [], projects: [], error_oauth: null }
    case actionTypes.LOAD_REPORT_SUCCESS:
      return { ...state, report: action.report, plan: action.plan }
    case actionTypes.LOAD_REPORT_FAILURE:
      return { ...state, report: '', plan: '' }
    case actionTypes.LOAD_REPORTS_SUCCESS:
      return { ...state, reports: action.reports }
    case actionTypes.LOAD_REPORTS_FAILURE:
      return { ...state, reports: [] }
    case actionTypes.SET_REPORT:
      return { ...state, report: action.report }
    case actionTypes.SET_JIRA_SERVER_OAUTH_STATUS:
      return { ...state, jiraServerAuthorized: action.authorized }
    case actionTypes.SET_PLAN:
      return { ...state, plan: action.plan }
    case actionTypes.SET_REPORT_SAVED:
      return { ...state, isSaved: true }
    case actionTypes.REMOVE_REPORT_ISSAVED:
      return { ...state, isSaved: false }
    case actionTypes.HANDLE_OAUTH_ERROR:
      return { ...state, error_oauth: action.error_oauth, jiraState: "loaded" }
    case actionTypes.SET_JIRA_STATE:
      return { ...state, jiraState: action.jiraState }
    case actionTypes.GET_WORKLOGS_SUCCESS:
      return { ...state, report: action.report, plan: action.plan, jiraState: "loaded" }
    case actionTypes.GET_WORKLOGS_FAILURE:
      return { ...state, report: '', plan: '', error_oauth: null }
    default:
      return state
  }
}

export default reducer
