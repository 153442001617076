class PermissionLink {
  constructor(label, groups, isAdditional = false, link = null) {
    this.label = label
    this.groups = groups
    this.isAdditional = isAdditional
    this.link = link ? link : label.toString().toLowerCase()
  }
  hasPermission = (userGroups) => {
    return userGroups && userGroups.some((group) => this.groups.includes(group))
  }
}

export default [
  new PermissionLink('Reports', ['admins', 'viewers', 'accountants', 'managers'], false, 'reports/for_employee'),
  new PermissionLink('Dashboard', ['viewers']),
  new PermissionLink('Employees', ['admins', 'managers', 'headhunters']),
  new PermissionLink('Projects', ['admins', 'managers']),
  new PermissionLink('Managers', ['admins']),
  new PermissionLink('Table Mode Stats', ['admins', 'managers', 'accountants'], true, 'tablemodestats'),
  new PermissionLink('Report', ['viewers', 'managers', 'admins', 'headhunters', 'accountants']),
  new PermissionLink('Jira Worklog Stats', ['admins', 'managers'], true, 'jira-worklog-stats'),
  new PermissionLink('Milestones', ['admins']),
  new PermissionLink('Settings', ['admins'], true),
  new PermissionLink('Billable Hours', ['admins', 'managers'], true, 'billable-hours'),
  new PermissionLink('Billing Periods', ['admins', 'managers'], true, 'billing-periods')
]
