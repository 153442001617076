import { combineReducers } from 'redux'

import employeesReducer from './employees'
import globalReducer from './global'
import projectsReducer from './projects'
import reportsReducer from './reports'
import technologiesReducer from './technologies'
import chatsReducer from './chats'
import seniorsReducer from './seniorManagers'
import tableModeReducer from './tableMode'
import worklogStatsReducer from './worklogStats'
import milestonesReducer from './milestones'
import statsReducer from './stats'
import headerReducer from './header'
import billableHoursReducer from './billableHours'
import billableHoursProjectsReducer from './billableHoursProjects'
import billingPeriodsReducer from './billingPeriods'
import sentryOrgReducer from './sentryOrg'

export const rootReducer = combineReducers({
  emp: employeesReducer,
  global: globalReducer,
  proj: projectsReducer,
  rep: reportsReducer,
  tech: technologiesReducer,
  chats: chatsReducer,
  seniors: seniorsReducer,
  tablemode: tableModeReducer,
  worklogstats: worklogStatsReducer,
  milestones: milestonesReducer,
  stats: statsReducer,
  header: headerReducer,
  billableHours: billableHoursReducer,
  billableHoursProjects: billableHoursProjectsReducer,
  billingPeriods: billingPeriodsReducer,
  sentryOrg: sentryOrgReducer
})
