import * as actionTypes from '../actions/actionTypes'

const initialState = {
  hours: [],
  weeks: [],
  stats: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_BILLABLE_HOURS_WEEKS_SUCCESS:
      return { ...state, weeks: action.weeks }
    case actionTypes.LOAD_BILLABLE_HOURS_WEEKS_FAILURE:
      return { ...state, weeks: [] }
    case actionTypes.LOAD_BILLABLE_HOURS_SUCCESS:
      return { ...state, hours: action.billableHours }
    case actionTypes.LOAD_BILLABLE_HOURS_FAILURE:
      return { ...state, hours: [] }
    case actionTypes.UPDATE_BILLABLE_HOURS_SUCCESS:
      return { ...state, hours: action.billableHours }
    case actionTypes.UPDATE_BILLABLE_HOURS_FAILURE:
      return { ...state }
    case actionTypes.LOAD_STATS:
      return { ...state, stats: action.stats }
    default:
      return state
  }
}

export default reducer
