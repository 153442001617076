import * as actionTypes from '../actions/actionTypes'

const initialState = {
  sentryOrg: [],
  isLoaded: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SENTRY_ORG_SUCCESS: {
      const { sentryOrg } = action.payload
      return { ...state, sentryOrg, isLoaded: true }
    }
    case actionTypes.LOAD_SENTRY_ORG_FAILURE: {
      return { ...state, sentryOrg: [], isLoaded: false }
    }
    default:
      return state
  }
}

export default reducer
