/* eslint-disable no-undef */
import React from 'react'

import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import WebFont from 'webfontloader'

import { Provider } from 'react-redux'
import { store } from './store/configureStore'

import './assets/css/vendor/bootstrap.min.css'
import './assets/css/vendor/bootstrap.rtl.only.min.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './assets/css/sass/themes/gogo.light.blueyale.scss'
import './assets/fonts/simple-line-icons/css/simple-line-icons.css'
import './assets/css/sass/customStyle/app.scss'

import App from './App'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from "@sentry/tracing"

const dotenv = require('dotenv')
const dotenvExpand = require('dotenv-expand')

const myEnv = dotenv.config()
dotenvExpand.expand(myEnv)

process.env.NODE_ENV === 'production' && Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0
})


ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

WebFont.load({ google: { families: ['Roboto:300,400,700', 'sans-serif'] } })
