import * as actionTypes from '../actions/actionTypes'

const initialState = {
  summary: [],
  periods: [],
  billingPeriods: {},
  isDeleted: '',
  // savedManager: null,
  periodIsAdded: '',
  isUpdated: ''
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SUMMARY_DATA:
      return { ...state, summary: action.data }
    case actionTypes.LOAD_PERIOD_DATA:
      return { ...state, periods: action.data }
    case actionTypes.LOAD_PERIODS_DATA:
      return { ...state, billingPeriods: action.data }
    case actionTypes.DELETE_BILLING_PERIOD:
      return { ...state, idDeleted: 'Done!' }
    // case actionTypes.SAVE_MANAGER:
    //   return { ...state, savedManager: action.data }
    case actionTypes.ADD_PERIOD_TO_PROJECTS:
      return { ...state, periodIsAdded: 'Done!' }
    case actionTypes.RESET_DATA:
      return {
        ...state, summary: [], isDeleted: '', periodIsAdded: '', billingPeriods: {}, periods: []
      }
    case actionTypes.UPADTE_PERIOD:
      return { ...state, isUpdated: action.data }
    default:
      return state
  }
}

export default reducer