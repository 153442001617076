// GLOBAL
export const GLOBAL_LOAD_REQUEST = 'GLOBAL_LOAD_REQUEST'
export const GLOBAL_LOAD_SUCCESS = 'GLOBAL_LOAD_SUCCESS'
export const GLOBAL_LOAD_FAILURE = 'GLOBAL_LOAD_FAILURE'
export const GLOBAL_SAVE_SUCCESS = 'GLOBAL_SAVE_SUCCESS'
export const GLOBAL_SAVE_FAILURE = 'GLOBAL_SAVE_FAILURE'
export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS'
export const LOAD_PROFILE_FAILURE = 'LOAD_PROFILE_FAILURE'
export const GLOBAL_SNACKBAR_OPEN = 'GLOBAL_SNACKBAR_OPEN'
export const GLOBAL_SNACKBAR_CLOSE = 'GLOBAL_SNACKBAR_CLOSE'
export const GLOBAL_INPUT_FAILURE = 'GLOBAL_INPUT_FAILURE'
export const GLOBAL_SHOW_NOTIFICATION = 'GLOBAL SHOW NOTIFICATION'
export const GLOBAL_COMMON_FAILURE = 'GLOBAL_COMMON_FAILURE'
export const GLOBAL_SET_LANGUAGE = 'GLOBAL_SET_LANGUAGE'
export const SAVE_LOCATION = 'SAVE_LOCATION'

// EMPLOYEES
export const LOAD_EMPLOYEE_SUCCESS = 'LOAD_EMPLOYEE_SUCCESS'
export const LOAD_EMPLOYEE_FAILURE = 'LOAD_EMPLOYEE_FAILURE'
export const LOAD_EMPLOYEES_SUCCESS = 'LOAD_EMPLOYEES_SUCCESS'
export const LOAD_EMPLOYEES_FAILURE = 'LOAD_EMPLOYEES_FAILURE'
export const LOAD_MANAGERS_SUCCESS = 'LOAD_MANAGERS_SUCCESS'
export const LOAD_MANAGERS_FAILURE = 'LOAD_MANAGERS_FAILURE'
export const SAVE_EMPLOYEE_SUCCESS = 'SAVE_EMPLOYEE_SUCCESS'
export const SAVE_EMPLOYEE_FAILURE = 'SAVE_EMPLOYEE_FAILURE'
export const REMOVE_SELECTED_EMPLOYEE = 'REMOVE_SELECTED_EMPLOYEE'
export const REMOVE_LOADED_EMPLOYEES = 'REMOVE_LOADED_EMPLOYEES'
export const SET_EMPLOYEE_REMINDER = 'SET_EMPLOYEE_REMINDER'
export const DELETE_EMPLOYEE_REMINDER = 'DELETE_EMPLOYEE_REMINDER'
export const SET_EMPLOYEE_VACATION = 'SET_EMPLOYEE_VACATION'
export const SET_EMPLOYEE_PROJECTS = 'SET_EMPLOYEE_PROJECTS'
export const SET_EMPLOYEE_MANAGERS_PROJECT = 'SET_EMPLOYEE_MANAGERS_PROJECT'
export const SET_EMPLOYEE_TECHNOLOGIES = 'SET_EMPLOYEE_TECHNOLOGIES'
export const SET_EMPLOYEE_FLAG = 'SET_EMPLOYEE_FLAG'
export const SET_EMPLOYEE_GROUPS = 'SET_EMPLOYEE_GROUPS'
export const SET_SELECTED_TECHNOLOGIES = 'SET_SELECTED_TECHNOLOGIES'

// PROJECTS
export const LOAD_PROJECT_SUCCESS = 'LOAD_PROJECT_SUCCESS'
export const LOAD_PROJECT_FAILURE = 'LOAD_PROJECT_FAILURE'
export const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS'
export const LOAD_PROJECTS_FAILURE = 'LOAD_PROJECTS_FAILURE'
export const SAVE_PROJECT_SUCCESS = 'SAVE_PROJECT_SUCCESS'
export const SAVE_PROJECT_FAILURE = 'SAVE_PROJECT_FAILURE'
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS'
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE'
export const ADD_NEW_PROJECT = 'ADD_NEW_PROJECT'
export const ADD_PROJECT_SENTRY = 'ADD_PROJECT_SENTRY'
export const DELETE_PROJECT_SENTRY = 'DELETE_PROJECT_SENTRY'
export const UPDATE_PROJECT_SENTRY_FIELD = 'UPDATE_PROJECT_SENTRY_FIELD'
export const DELETE_ORG_FIELD_FROM_SENTRY = 'DELETE_ORG_FIELD_FROM_SENTRY'
export const REMOVE_SELECTED_PROJECT = 'REMOVE_SELECTED_PROJECT'
export const REMOVE_LOADED_PROJECTS = 'REMOVE_LOADED_PROJECTS'
export const REMOVE_LOADED_PROJECTS_FOR_REQUESTS =
  'REMOVE_LOADED_PROJECTS_FOR_REQUESTS'
export const SET_PROJECT_CHATROOM = 'SET_PROJECT_CHATROOM'
export const SET_PROJECT_MANAGERS = 'SET_PROJECT_MANAGERS'
export const SET_PROJECT_EMPLOYEES = 'SET_PROJECT_EMPLOYEES'
export const SET_PROJECT_SENIOR = 'SET_PROJECT_SENIOR'
export const SET_PROJECT_TABLE_MODE = 'SET_PROJECT_TABLE_MODE'
export const SET_PROJECT_HUBSTAFF_ENABLED = 'SET_PROJECT_HUBSTAFF_ENABLED'
export const SET_PROJECT_MILESTONE_REQUIRED = 'SET_PROJECT_MILESTONE_REQUIRED'
export const SET_PROJECT_SEND_BB_INFO_TO_GENERAL_CHAT =
  'SET_PROJECT_SEND_BB_INFO_TO_GENERAL_CHAT'
export const SET_PROJECT_NAME = 'SET_PROJECT_NAME'
export const SET_PROJECT_GIT_LINKS = 'SET_PROJECT_GIT_LINKS'
export const SET_PROJECT_JENKINS_JOBS = 'SET_PROJECT_JENKINS_JOBS'
export const SET_PROJECT_TECHNOLOGIES = 'SET_PROJECT_TECHNOLOGIES'
export const SET_PROJECT_DESCRIPTION = 'SET_PROJECT_DESCRIPTION'
export const LOAD_PROJECT_REQUESTS_SUCCESS = 'LOAD_PROJECT_REQUESTS_SUCCESS'
export const LOAD_PROJECT_REQUESTS_FAILURE = 'LOAD_PROJECT_REQUESTS_FAILURE'
export const REQUEST_PROJECT_ACCESS_SUCCESS = 'REQUEST_PROJECT_ACCESS_SUCCESS'
export const LOAD_PROJECTS_FOR_REQUESTS_SUCCESS =
  'LOAD_PROJECTS_FOR_REQUESTS_SUCCESS'
export const SET_PROJECT_NOTIFICATION_TIME = 'SET_PROJECT_NOTIFICATION_TIME'
export const SET_PROJECT_NOTIFICATION_REMINDER_TODAY =
  'SET_PROJECT_NOTIFICATION_REMINDER_TODAY'
export const SET_PROJECT_BILLABLE_HOURS_ENABLED =
  'SET_PROJECT_BILLABLE_HOURS_ENABLED'
export const SET_PROJECT_SEND_OVERDUE_REPORT_NOTIFICATION =
  'SET_PROJECT_SEND_OVERDUE_REPORT_NOTIFICATION'

// TECHNOLOGIES
export const LOAD_TECHNOLOGIES_SUCCESS = 'LOAD_TECHNOLOGIES_SUCCESS'
export const LOAD_TECHNOLOGIES_FAILURE = 'LOAD_TECHNOLOGIES_FAILURE'
export const CREATE_TECHNOLOGY_SUCCESS = 'CREATE_TECHNOLOGY_SUCCESS'
export const CREATE_TECHNOLOGY_FAILURE = 'CREATE_TECHNOLOGY_FAILURE'
export const DELETE_TECHNOLOGY_SUCCESS = 'DELETE_TECHNOLOGY_SUCCESS'
export const DELETE_TECHNOLOGY_FAILURE = 'DELETE_TECHNOLOGY_FAILURE'

// CHATS
export const LOAD_CHATS_SUCCESS = 'LOAD_CHATS_SUCCESS'
export const LOAD_CHATS_FAILURE = 'LOAD_CHATS_FAILURE'

// SENIORMANAGERS
export const LOAD_SENIORS_SUCCESS = 'LOAD_SENIORS_SUCCESS'
export const LOAD_SENIORS_FAILURE = 'LOAD_SENIORS_FAILURE'
export const LOAD_SENIOR_SUCCESS = 'LOAD_SENIOR_SUCCESS'
export const LOAD_SENIOR_FAILURE = 'LOAD_SENIOR_FAILURE'
export const REMOVE_SELECTED_SENIOR = 'REMOVE_SELECTED_SENIOR'

// REPORTS
export const LOAD_REPORTS_INFO_SUCCESS = 'LOAD_REPORTS_INFO_SUCCESS'
export const LOAD_REPORTS_INFO_FAILURE = 'LOAD_REPORTS_INFO_FAILURE'
export const LOAD_REPORT_SUCCESS = 'LOAD_REPORT_SUCCESS'
export const LOAD_REPORT_FAILURE = 'LOAD_REPORT_FAILURE'
export const LOAD_REPORTS_SUCCESS = 'LOAD_REPORTS_SUCCESS'
export const LOAD_REPORTS_FAILURE = 'LOAD_REPORTS_FAILURE'
export const GET_WORKLOGS_SUCCESS = 'GET_WORKLOGS_SUCCESS'
export const GET_WORKLOGS_FAILURE = 'GET_WORKLOGS_FAILURE'
export const SET_REPORT = 'SET_REPORT'
export const SET_PLAN = 'SET_PLAN'
export const SET_REPORT_SAVED = 'SET_REPORT_SAVED'
export const REMOVE_REPORT_ISSAVED = 'REMOVE_REPORT_ISSAVED'
export const HANDLE_OAUTH_ERROR = 'HANDLE_OAUTH_ERROR'
export const SET_JIRA_STATE = 'SET_JIRA_STATE'
export const SET_JIRA_SERVER_OAUTH_STATUS = 'SET_JIRA_SERVER_OAUTH_STATUS'

// TABLEMODE
export const LOAD_TABLE_MODE_STATS_SUCCESS = 'LOAD_TABLE_MODE_STATS_SUCCESS'
export const LOAD_TABLE_MODE_STATS_FAILURE = 'LOAD_TABLE_MODE_STATS_FAILURE'
export const REMOVE_LOADED_TABLE_MODE_STATS = 'REMOVE_LOADED_TABLE_MODE_STATS'

// WORKLOG STATS
export const LOAD_JIRA_EMPLOYEE_SERVERS_SUCCESS =
  'LOAD_JIRA_EMPLOYEE_SERVERS_SUCCESS'
export const LOAD_JIRA_EMPLOYEE_SERVERS_FAILURE =
  'LOAD_JIRA_EMPLOYEE_SERVERS_FAILURE'
export const LOAD_JIRA_EMPLOYEES_WORKING_TIME_SUCCESS =
  'LOAD_JIRA_EMPLOYEES_WORKING_TIME_SUCCESS'
export const LOAD_JIRA_EMPLOYEES_WORKING_TIME_FAILURE =
  'LOAD_JIRA_EMPLOYEES_WORKING_TIME_FAILURE'
export const SET_WORKLOG_PROJECTS = 'SET_WORKLOG_PROJECTS'

// MILESTONES
export const LOAD_MILESTONES_SUCCESS = 'LOAD_MILESTONES_SUCCESS'
export const LOAD_MILESTONES_FAILURE = 'LOAD_MILESTONES_FAILURE'

// BILLABLE_HOURS
export const LOAD_BILLABLE_HOURS_WEEKS_SUCCESS =
  'LOAD_BILLABLE_HOURS_WEEKS_SUCCESS'
export const LOAD_BILLABLE_HOURS_WEEKS_FAILURE =
  'LOAD_BILLABLE_HOURS_WEEKS_FAILURE'
export const LOAD_BILLABLE_HOURS_SUCCESS = 'LOAD_BILLABLE_HOURS_SUCCESS'
export const LOAD_BILLABLE_HOURS_FAILURE = 'LOAD_BILLABLE_HOURS_FAILURE'
export const UPDATE_BILLABLE_HOURS_SUCCESS = 'UPDATE_BILLABLE_HOURS_SUCCESS'
export const UPDATE_BILLABLE_HOURS_FAILURE = 'UPDATE_BILLABLE_HOURS_FAILURE'

//STATS
export const SET_NO_PROJECT_OPTION_MESSAGE = 'SET_NO_PROJECT_OPTION_MESSAGE'
export const SET_NO_EMPLOYEE_OPTION_MESSAGE = 'SET_NO_EMPLOYEE_OPTION_MESSAGE'
export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT'
export const SET_SELECTED_EMPLOYEE = 'SET_SELECTED_EMPLOYEE'
export const IS_FOR_EMPLOYEE = 'IS_FOR_EMPLOYEE'
export const IS_HIDING_INACTIVE = 'IS_HIDING_INACTIVE'
export const SET_DATE = 'SET_DATE'
export const SET_CALENDAR_DATA = 'SET_CALENDAR_DATA'
export const CLEAR_CALENDAR_DATA = 'CLEAR_CALENDAR_DATA'
export const SET_SELECTED_EVENTS = 'SET_SELECTED_EVENTS'
export const LOAD_STATS_REPORTS_SUCCESS = 'LOAD_STATS_REPORTS_SUCCESS'
export const LOAD_STATS_REPORTS_FAILURE = 'LOAD_STATS_REPORTS_FAILURE'
export const LOAD_STATS_PROJECTS_SUCCESS = 'LOAD_STATS_PROJECTS_SUCCESS'
export const LOAD_STATS_PROJECTS_FOR_REQUESTS_SUCCESS =
  'LOAD_STATS_PROJECTS_FOR_REQUESTS_SUCCESS'
export const LOAD_STATS_PROJECTS_FAILURE = 'LOAD_STATS_PROJECTS_FAILURE'
export const LOAD_STATS_EMPLOYEES_SUCCESS = 'LOAD_STATS_EMPLOYEES_SUCCESS'
export const LOAD_STATS_EMPLOYEES_FAILURE = 'LOAD_STATS_EMPLOYEES_FAILURE'
export const LOAD_STATS_EMPLOYEE_SUCCESS = 'LOAD_STATS_EMPLOYEE_SUCCESS'
export const LOAD_STATS_EMPLOYEE_FAILURE = 'LOAD_STATS_EMPLOYEE_FAILURE'
export const LOAD_STATS = 'LOAD_STATS'
export const FETCH_INFO_FOR_ALL_REPORTS = 'FETCH_INFO_FOR_ALL_REPORTS'
export const CLEAR_ALL_REPORTS = 'CLEAR_ALL_REPORTS'

//BILLABLE HOURS PROJECTS
export const UPDATE_BILLABLE_HOURS_PROJECTS_STATS_SUCCESS =
  'UPDATE_BILLABLE_HOURS_PROJECTS_STATS_SUCCESS'
export const UPDATE_BILLABLE_HOURS_PROJECTS_STATS_FAILURE =
  'UPDATE_BILLABLE_HOURS_PROJECTS_STATS_FAILURE'
export const UPDATE_BILLABLE_HOURS_PROJECTS_SUCCESS =
  'UPDATE_BILLABLE_HOURS_PROJECTS_SUCCESS'
export const UPDATE_BILLABLE_HOURS_PROJECTS_FAILURE =
  'UPDATE_BILLABLE_HOURS_PROJECTS_FAILURE'

//HEADER
export const SET_SELECTED_PAGE = 'SET_SELECTED_PAGE'
export const SET_MENU_BUTTON_HOVER = 'SET_MENU_BUTTON_HOVER'

//BILLING PERIODS
export const LOAD_SUMMARY_DATA = 'LOAD_SUMMARY_DATA'
export const LOAD_PERIOD_DATA = 'LOAD_PERIOD_DATA'
export const ADD_PERIOD_TO_PROJECTS = 'ADD_PERIOD_TO_PROJECTS'
export const DELETE_BILLING_PERIOD = 'DELETE_BILLING_PERIOD'
export const LOAD_PERIODS_DATA = 'LOAD_PERIODS_DATA'
export const SAVE_MANAGER = 'SAVE_MANAGER'
export const RESET_DATA = 'RESET_DATA'
export const UPADTE_PERIOD = 'UPADTE_PERIOD'
export const SET_PROJECT_COMMERCIAL = 'SET_PROJECT_COMMERCIAL'

// SENTRY ORG
export const LOAD_SENTRY_ORG_SUCCESS = 'LOAD_SENTRY_ORG_SUCCESS'
export const LOAD_SENTRY_ORG_FAILURE = 'LOAD_SENTRY_ORG_FAILURE'
