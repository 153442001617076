import { push, set, del } from 'object-path-immutable'
import * as actionTypes from '../actions/actionTypes'

const initialState = {
  employees: null,
  employeeOnServer: null,
  employee: null,
  senior_managers: null,
  selectedTechnologies: []
}

const reducer = (state = initialState, action) => {
  let updatedState
  switch (action.type) {
    case actionTypes.LOAD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employeeOnServer: action.employee,
        employee: action.employee
      }
    case actionTypes.LOAD_EMPLOYEE_FAILURE:
      return { ...state, employeeOnServer: null, employee: null }
    case actionTypes.LOAD_EMPLOYEES_SUCCESS:
      return { ...state, employees: action.employees }
    case actionTypes.LOAD_EMPLOYEES_FAILURE:
      return { ...state, employees: null }
    case actionTypes.LOAD_MANAGERS_SUCCESS:
      return { ...state, senior_managers: action.senior_managers }
    case actionTypes.LOAD_MANAGERS_FAILURE:
      return { ...state, senior_managers: null }
    case actionTypes.SET_EMPLOYEE_REMINDER:
      updatedState =
        action.reminder_index === null
          ? push(
            state,
            `employee.${action.reminder_type}`,
            action.reminder_time
          )
          : set(
            state,
            `employee.${action.reminder_type}.${action.reminder_index}`,
            action.reminder_time
          )
      return { ...state, employee: updatedState.employee }
    case actionTypes.DELETE_EMPLOYEE_REMINDER:
      updatedState = del(
        state,
        `employee.${action.reminder_type}.${action.reminder_index}`
      )
      return { ...state, employee: updatedState.employee }
    case actionTypes.SAVE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employeeOnServer: action.employee,
        employee: action.employee
      }
    case actionTypes.SAVE_EMPLOYEE_FAILURE:
      return { ...state, employee: state.employeeOnServer }
    case actionTypes.REMOVE_SELECTED_EMPLOYEE:
      return { ...state, employeeOnServer: null, employee: null }
    case actionTypes.REMOVE_LOADED_EMPLOYEES:
      return { ...state, employees: null }
    case actionTypes.SET_EMPLOYEE_PROJECTS:
      updatedState = set(state, `employee.projects`, action.projects)
      return { ...state, employee: updatedState.employee }
    case actionTypes.SET_EMPLOYEE_MANAGERS_PROJECT:
      updatedState = set(
        state,
        `employee.managers_projects`,
        action.managers_projects
      )
      return { ...state, employee: updatedState.employee }
    case actionTypes.SET_EMPLOYEE_TECHNOLOGIES:
      updatedState = action.isNew
        ? push(state, `employee.technologies`, action.technologies)
        : set(state, `employee.technologies`, action.technologies)
      return { ...state, employee: updatedState.employee }
    case actionTypes.SET_EMPLOYEE_FLAG:
      updatedState = set(state, `employee.${action.flag}`, action.value)
      return { ...state, employee: updatedState.employee }
    case actionTypes.SET_EMPLOYEE_VACATION:
      updatedState =
        action.index === null
          ? push(state, `employee.future_vacations`, action.vacation)
          : set(
            state,
            `employee.future_vacations.${action.index}`,
            action.vacation
          )
      return { ...state, employee: updatedState.employee }
    case actionTypes.SET_EMPLOYEE_GROUPS:
      if (action.checked && !state.employee.groups.includes(action.group)) {
        updatedState = push(state, `employee.groups`, action.group)
      }
      else if (
        !action.checked &&
        state.employee.groups.includes(action.group)
      ) {
        const updatedGroups = state.employee.groups.filter(
          (item) => item !== action.group
        )
        updatedState = set(state, `employee.groups`, updatedGroups)
      }
      if (updatedState !== null)
        return { ...state, employee: updatedState.employee }
      return { ...state }
    case actionTypes.SET_SELECTED_TECHNOLOGIES:
      return { ...state, selectedTechnologies: action.payload }
    default:
      return state
  }
}

export default reducer
