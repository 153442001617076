import * as actionTypes from '../actions/actionTypes'

const initialState = {
  billableHoursProjectsStats: null,
  billableHoursProjects: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_BILLABLE_HOURS_PROJECTS_STATS_SUCCESS:
      return { ...state, billableHoursProjectsStats: action.billableHoursProjectsStats }
    case actionTypes.UPDATE_BILLABLE_HOURS_PROJECTS_STATS_FAILURE:
      return { ...state }
    case actionTypes.UPDATE_BILLABLE_HOURS_PROJECTS_SUCCESS:
      return { ...state, billableHoursProjects: action.billableHoursProjects }
    case actionTypes.UPDATE_BILLABLE_HOURS_PROJECTS_FAILURE:
      return { ...state }
    default:
      return state
  }
}

export default reducer
