import React from 'react'

import moment from 'moment'

export const formatStringDate = (date) => {
  const parsedDate = moment(date)
  if (parsedDate === 'Invalid date') return date
  const day = parsedDate.format('DD', { trim: false })
  const month = parsedDate.format('MMM')
  const year = parsedDate.format('YYYY')
  return `${day} ${month} ${new Date().getFullYear() === year ? `` : year}`
}

export const formatName = (employee) => {
  const format = (value) => (value ? value : '')
  return (
    format(employee.editable_first_name) +
    ' ' +
    format(employee.editable_last_name)
  )
}

export const formatEmployeeIsActive = (employee, name) => {
  const format = (value) => (employee.is_active ? value : <del>{value}</del>)
  return format(name)
}

export const formatLdapName = (employee) => {
  const format = (value) => (value ? ' ' + value : '')
  return format(employee.ldap_username)
}

export const parseDate = (dateStr) => {
  const date = new Date()
  const regex = /(?:GMT)([-+]\d*)/gm
  let gtm = regex.exec(date.toString())[1]
  gtm = [...gtm]
  gtm.splice(3, 0, ':')
  gtm = gtm.join('')
  return new Date(dateStr + gtm)
}

export const tryParseJSONgroups = (str) => {
  try {
    return JSON.parse(str)
  }
  catch (e) {
    e
  }
  return []
}

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date)
}

export const arrayToText = (data) => {
  if (!data || data.length === 0) return ''
  if (typeof data === 'string')
    return data
  return data.join('\n')
}

export const textToArray = (data) => {
  if (!data) return []
  if (typeof data === 'string')
    return data.split('\n').map((str) => str.trim())
  return data
}

export const formatBillableHours = (billableHours) => {
  let renderHours = []
  let renderDays = new Set()
  let employees = []
  if (billableHours && !billableHours.Message) {
    billableHours.map((item) => renderDays.add(item.date))
    billableHours.map((item) => {
      if (!employees.includes(employees.find((emp) => emp.id === item.employee.id)))
        employees.push(item.employee)
    })
    renderDays = [...renderDays].sort()
    for (let emp of employees) {
      let empHours = []
      for (let day of renderDays)
        for (let bhour of billableHours)
          if (bhour.date === day && bhour.employee.id === emp.id)
            empHours.push(bhour)
      renderHours.push({ emp, hours: empHours })
    }
  }
  return { renderHours, renderDays }
}

export const formatAxiosError = (error) => {
  let error_text = 'Error'
  if (Object.keys(error).includes('framesToPop'))
    return (error_text += ' ' + error.name + '!')
  error_text += '!'
  if (!error.response)
    return error_text
  if (error.response.data.detail && error.response.status !== 500)
    error_text += ' ' + error.response.data.detail
  if (error.response.data.error && error.response.status !== 500)
    error_text += ' ' + error.response.data.error
  if (error.response.status === 500)
    error_text += ' Something is wrong with server.'
  if (error.response.data && typeof error.response.data === 'string')
    error_text += error.response.data
  if (!error.response.data) {
    error_text += error.response.status ? ' ' + error.response.status + '!' : ''
    error_text += error.response.statusText ? ' ' + error.response.statusText + '.' : ''
  }
  return error_text
}

export const formatError = (error_message) => {
  let error_text = error_message
  if (!error_message) error_text += ' Error!'
  return error_text
}

export const doesUserHasAccess = (user, project_id) => {
  return user.groups.includes('admins') ||
    (user.groups.includes('managers') &&
      user.managers_projects.filter((proj) => proj.id === project_id))
    ? true
    : false
}

export const getProjectData = (project, userId = null) => {
  return {
    sentry_projects: project.sentry_projects?.filter((sentry) => sentry.id !== 0).map((sentry) => {
      const { _key, ...rest } = sentry
      return rest
    }),
    git_links: textToArray(project.git_links),
    jenkins_jobs: textToArray(project.jenkins_jobs),
    description: project.description,
    name: project.name === '' ? null : project.name,
    table_mode: Boolean(project.table_mode),
    is_hubstaff_enabled: project.is_hubstaff_enabled,
    is_commercial: project.is_commercial,
    is_milestone_required: project.is_milestone_required,
    is_billable_hours_enabled: project.is_billable_hours_enabled,
    send_bb_info_to_general_chat: project.send_bb_info_to_general_chat,
    send_overdue_report_notification: project.send_overdue_report_notification,
    senior_manager_id: project.current_senior_manager?.id ?? userId,
    managers_ids:
      project.managers === null || !project.managers || project.managers.length === 0
        ? [userId]
        : project.managers.map((manager) => manager.id),
    employees_ids:
      project.employees === null || !project.employees || project.employees.length === 0
        ? []
        : project.employees.map((employee) => employee.id),
    technologies_ids:
      project.technologies === null || !project.technologies || project.technologies.length === 0
        ? []
        : project.technologies.map((tech) => tech.id),
    matrix_room_id: project.matrix_room?.id ?? null,
    manager_matrix_room_id: project.manager_matrix_room?.id ?? null,
    manager_chat_id: project.manager_chat?.id ?? null,
    notification_reminder_today:
      project.notification_reminder_today === null ? false : project.notification_reminder_today,
    notification_time: project.notification_time ? project.notification_time : '21:00:00'
  }
}

export const addKeyProperty = (project) => ({
  ...project,
  sentry_projects: project.sentry_projects?.map((sentry) => {
    sentry._key = crypto.randomUUID()
    return sentry
  })
})
