import React from 'react'

export const useThrottle = (callback, delay) => {
  const isThrottled = React.useRef(null)

  const throttleCallback = React.useCallback((...args) => {
    if (isThrottled.current)
      return

    callback(args)

    isThrottled.current = true
    setTimeout(() => isThrottled.current = false, delay)
  }, [callback, delay])

  return throttleCallback
}