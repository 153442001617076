import * as actionTypes from './actionTypes'

import axios from 'axios'

import { Auth } from '@utils/helpers/auth'
import { formatAxiosError } from '@utils/util'

export const setWorklogProjects = (selected) => {
  let projects = []
  if (selected)
    projects = selected.map((entry) => {
      return { id: entry['value'], name: entry['label'] }
    })
  return { type: actionTypes.SET_WORKLOG_PROJECTS, projects: projects }
}

export const loadJiraEmployeeServers = (server = null) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.GLOBAL_LOAD_REQUEST })
    try {
      const response = await axios.get('api/jira_stats/employee_servers/', { headers: Auth.withAuth({}) })
      const projects = response.data.jira_servers.map((server) => {
        return { server_id: server.id, projects: server.projects }
      })
      dispatch({
        type: actionTypes.LOAD_JIRA_EMPLOYEE_SERVERS_SUCCESS,
        jira_servers: response.data.jira_servers,
        jira_projects: projects
      })
      if (server)
        dispatch({
          type: actionTypes.SET_JIRA_SERVER_OAUTH_STATUS,
          authorized: response.data.jira_servers.find((s) => s.id === server).status
        })
      dispatch({type: actionTypes.GLOBAL_LOAD_SUCCESS})
    }
    catch (error) {
      dispatch({ type: actionTypes.LOAD_JIRA_EMPLOYEE_SERVERS_FAILURE })
      dispatch({
        type: actionTypes.GLOBAL_LOAD_FAILURE,
        error: formatAxiosError(error),
        errorCode: error.response && error.response.status
      })
    }
  }
}

export const getEmployeesWorklogStats = (server, project, date, jqlQuery = null) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.GLOBAL_LOAD_REQUEST })
    try {
      const response = await axios.get('api/jira_stats/worklog_stats/', {
        headers: Auth.withAuth({}),
        params: {
          server_jira: server,
          jira_project: project === null ? null : JSON.stringify(project),
          jql_query: jqlQuery,
          date: date
        }
      })
      dispatch({
        type: actionTypes.LOAD_JIRA_EMPLOYEES_WORKING_TIME_SUCCESS,
        jira_employees_working_time: response.data.worklog_stats
      })
      dispatch({ type: actionTypes.GLOBAL_LOAD_SUCCESS })
    }
    catch (error) {
      dispatch({ type: actionTypes.LOAD_JIRA_EMPLOYEES_WORKING_TIME_FAILURE })
      dispatch({
        type: actionTypes.GLOBAL_LOAD_FAILURE,
        error: formatAxiosError(error),
        errorCode: error.response && error.response.status
      })
    }
  }
}
