import * as actionTypes from './actionTypes'

import axios from 'axios'

import { Auth } from '@utils/helpers/auth'
import { formatAxiosError } from '@utils/util'

import { loadJiraEmployeeServers } from './worklogStats'

export const loadReportInfo = (token = null, isOauthHook = false, serverId) => {
  console.log(`loadReportInfo: token=${token}, isOauthHook=${isOauthHook}, serverId=${serverId}`)
  return (dispatch) => {
    dispatch({ type: actionTypes.GLOBAL_LOAD_REQUEST })
    const headers = token ? null : Auth.withAuth({})
    axios.get('/api/report_token', {
      headers: headers,
      params: { token: token }
    })
      .then((response) => {
        dispatch({
          type: actionTypes.LOAD_REPORTS_INFO_SUCCESS,
          dates: response.data.dates,
          projects: response.data.projects,
          jiraServers: response.data.jira_servers
        })
        if (isOauthHook) {
          // opened oauth window, user confirmed that oauth is successful. Reload data, check if oauth is ok
          const serverAuthorized = response.data.jira_servers.find(s => s.id === serverId).authorized
          if (serverAuthorized)
            dispatch({ type: actionTypes.SET_JIRA_STATE, jiraState: "loaded" })
          else
            dispatch({ type: actionTypes.SET_JIRA_STATE, jiraState: "failed" })
          dispatch({ type: actionTypes.SET_JIRA_SERVER_OAUTH_STATUS, authorized: serverAuthorized })
        }
        dispatch({ type: actionTypes.GLOBAL_LOAD_SUCCESS })
      })
      .catch((error) => {
        dispatch({ type: actionTypes.LOAD_REPORTS_INFO_FAILURE })
        dispatch({
          type: actionTypes.GLOBAL_LOAD_FAILURE,
          error: formatAxiosError(error),
          errorCode: error.response && error.response.status
        })
      })
  }
}

export const loadReport = (token, date, project_id) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GLOBAL_LOAD_REQUEST })
    const headers = token ? null : Auth.withAuth({})
    axios.get('/api/report', {
      headers: headers,
      params: {
        token: token,
        date: date,
        project_id: project_id
      }
    })
      .then((response) => {
        dispatch({ type: actionTypes.LOAD_REPORT_SUCCESS, report: response.data.report, plan: response.data.plan })
        dispatch({ type: actionTypes.GLOBAL_LOAD_SUCCESS })
      })
      .catch((error) => {
        dispatch({ type: actionTypes.LOAD_REPORT_FAILURE })
        dispatch({
          type: actionTypes.GLOBAL_LOAD_FAILURE,
          error: formatAxiosError(error),
          errorCode: error.response && error.response.status
        })
      })
  }
}

export const loadReportsByEmployee = (employee, dateStr) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GLOBAL_LOAD_REQUEST })
    axios.get('/api/reports/by_employee', {
      params: {
        employee_id: employee.id,
        date: dateStr
      },
      headers: Auth.withAuth({})
    })
      .then((response) => {
        dispatch({ type: actionTypes.LOAD_REPORTS_SUCCESS, reports: response.data })
        dispatch({ type: actionTypes.GLOBAL_LOAD_SUCCESS })
      })
      .catch((error) => {
        dispatch({ type: actionTypes.LOAD_REPORTS_FAILURE })
        dispatch({
          type: actionTypes.GLOBAL_LOAD_FAILURE,
          error: formatAxiosError(error),
          errorCode: error.response && error.response.status
        })
      })
  }
}

export const loadReportsByProject = (project, dateStr) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GLOBAL_LOAD_REQUEST })
    axios.get('/api/reports/by_project', {
      params: {
        project_id: project.id,
        date: dateStr
      },
      headers: Auth.withAuth({})
    })
      .then((response) => {
        dispatch({ type: actionTypes.LOAD_REPORTS_SUCCESS, reports: response.data })
        dispatch({ type: actionTypes.GLOBAL_LOAD_SUCCESS })
      })
      .catch((error) => {
        dispatch({ type: actionTypes.LOAD_REPORTS_FAILURE })
        dispatch({
          type: actionTypes.GLOBAL_LOAD_FAILURE,
          error: formatAxiosError(error),
          errorCode: error.response && error.response.status
        })
      })
  }
}

export const saveReport = (token, selectedDate, selectedProjectId, report, plan) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GLOBAL_LOAD_REQUEST })
    const headers = token ? null : Auth.withAuth({})
    axios.post('/api/report',
      {
        token: token,
        date: selectedDate,
        project_id: selectedProjectId,
        report: report,
        plan: plan
      },
      { headers: headers }
    )
      .then((response) => {
        if (response.data.format_error) {
          dispatch({ type: actionTypes.GLOBAL_SAVE_FAILURE, error: formatAxiosError(response.data.format_error_text) })
        }
        else {
          dispatch({ type: actionTypes.GLOBAL_SAVE_SUCCESS, message: 'Success!!!' })
          dispatch({ type: actionTypes.SET_REPORT_SAVED })
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.GLOBAL_SAVE_FAILURE, error: formatAxiosError(error) })
      })
  }
}

export const openJiraOauth = (token, date, serverId) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.GLOBAL_LOAD_REQUEST })
    const headers = token ? null : Auth.withAuth({})
    try {
      const res = await axios.get(`/api/jira/oauth_url/`, {
        headers: headers,
        params: { token: token, jira_server_id: serverId }
      })
      dispatch({ type: actionTypes.SET_JIRA_STATE, jiraState: "awaiting_oauth" })
      window.open(res.data.url, '_blank')
      // let timer = setInterval(() => {
      //   if (oauth_page.closed) {
      //     clearInterval(timer)
      //     if (loadinfo)
      //       dispatch(loadInfo(token, selectedDate, true, server_id))
      //     else
      //       dispatch(loadJiraEmployeeServers(server_id))
      //   }
      // }, 500)
    }
    catch (error) {
      dispatch({
        type: actionTypes.GLOBAL_LOAD_FAILURE,
        error: formatAxiosError(error),
        errorCode: error.response && error.response.status
      })
      dispatch({ type: actionTypes.GET_WORKLOGS_FAILURE })
      dispatch({ type: actionTypes.SET_JIRA_STATE, jiraState: "failed" })
    }
  }
}

export const getJiraWorklogs = (token, date, serverId) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (!state.rep.jiraServerAuthorized) {
      dispatch(openJiraOauth(token, date, serverId))
    } else {
      dispatch({ type: actionTypes.SET_JIRA_STATE, jiraState: "loading" })
      dispatch({ type: actionTypes.GLOBAL_LOAD_REQUEST })
      const headers = token ? null : Auth.withAuth({})
      try {
        const res = await axios.get(`/api/jira/fetch_report/`, {
          headers: headers,
          params: {
            token: token,
            report_dt: date,
            jira_server_id: serverId
          }
        })
        if (res.data.error) {
          dispatch({
            type: actionTypes.HANDLE_OAUTH_ERROR,
            error_oauth: 'Jira auth failed: ' + res.data.error
          })
          dispatch({ type: actionTypes.GET_WORKLOGS_FAILURE })
          dispatch({
            type: actionTypes.GLOBAL_LOAD_FAILURE,
            error: formatAxiosError(res.data.error),
            errorCode: res.data.error.response && res.data.error.response.status
          })
        }
        else {
          dispatch({ type: actionTypes.GET_WORKLOGS_SUCCESS, report: res.data.text_worklogs, plan: res.data.text_plan })
          dispatch({ type: actionTypes.GLOBAL_SNACKBAR_OPEN })
          dispatch({ type: actionTypes.GLOBAL_LOAD_SUCCESS })
          dispatch({ type: actionTypes.SET_JIRA_SERVER_OAUTH_STATUS, authorized: true })
        }
      }
      catch (error) {
        dispatch({
          type: actionTypes.GLOBAL_LOAD_FAILURE,
          error: formatAxiosError(error),
          errorCode: error.response && error.response.status
        })
        dispatch({ type: actionTypes.GET_WORKLOGS_FAILURE })
        dispatch({ type: actionTypes.SET_JIRA_STATE, jiraState: "failed" })
        dispatch({ type: actionTypes.SET_JIRA_SERVER_OAUTH_STATUS, authorized: false })
      }
    }
  }
}
