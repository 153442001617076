import * as actionTypes from '../actions/actionTypes'

const initialState = {
  telegram_chats: null,
  matrix_rooms: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_CHATS_SUCCESS:
      return { ...state, [action.chatsType]: action.chatsOrRooms }
    case actionTypes.LOAD_CHATS_FAILURE:
      return { ...state, telegram_chats: null, matrix_rooms: null }
    default:
      return state
  }
}

export default reducer
