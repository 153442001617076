import * as actionTypes from '../actions/actionTypes'

const initialState = {
  senior_managers: [],
  senior_manager: null,
  manager_projects: null,
  projects: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SENIORS_SUCCESS:
      return {
        ...state,
        senior_managers: action.senior_managers,
        projects: action.projects
      }
    case actionTypes.LOAD_SENIORS_FAILURE:
      return { ...state, senior_managers: null, projects: null }
    case actionTypes.LOAD_SENIOR_SUCCESS:
      return {
        ...state,
        senior_manager: action.senior_manager,
        manager_projects: action.manager_projects
      }
    case actionTypes.LOAD_SENIOR_FAILURE:
      return { ...state, senior_manager: null, manager_projects: null }
    case actionTypes.REMOVE_SELECTED_SENIOR:
      return { ...state, senior_manager: null, manager_projects: null }
    default:
      return state
  }
}

export default reducer
