import React from 'react'

import * as Sentry from "@sentry/react"

import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom'
import { IntlProvider } from 'react-intl'

import { useDispatch, useSelector } from 'react-redux'
import { saveLocation } from '@store/actions/global'

import { LoginForm } from './components/login/LoginForm'
import { Main } from './components/main/Main'

import Report from './components/report/Report'
import Page404 from './components/Page404'
import Notification from './components/UI/Global/Notification'

import { PrivateRoute } from './utils/helpers/PrivateRoute'
import { Auth } from './utils/helpers/auth'
import permissions from '@utils/helpers/permissions'

import en from './compiled-lang/en.json' // english translation
import ru from './compiled-lang/ru.json' // russian translation

const languageImportSelect = (language) => {
  switch (language) {
    case 'ru':
      return ru
    case 'en':
      return en
  }
}

const App = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const {
    error, errorCode, message, snackbarOpen, language, groups
  } = useSelector((state) => state.global)

  const availableLinks = permissions.filter((permissionLink) => permissionLink.hasPermission(groups))

  React.useEffect(() => {
    if (!location.pathname.includes('login'))
      dispatch(saveLocation(location.pathname))

    // if (
    //   availableLinks.filter((item) => location.pathname.substring(1) === item.link).length === 0 &&
    //   location.pathname !== '/login'
    // )
    //   history.push('/')
  }, [])

  if (errorCode === 401)
    Auth.logout(location)

  const closeSnackbar = () => dispatch({ type: 'GLOBAL_SNACKBAR_CLOSE' })

  return (
    <IntlProvider
      locale={language.toLowerCase()}
      messages={languageImportSelect(language.toLowerCase())}
      defaultLocale="en"
    >
      <Switch>
        <Redirect exact from="/" to="/reports" />
        <Route exact path="/login" component={LoginForm} />
        <Route path="/report/:token" component={Report} />
        <PrivateRoute
          // eslint-disable-next-line max-len
          path="/:page(reports|report|dashboard|employees|projects|managers|tablemodestats|jira-worklog-stats|ldap-update|milestones|settings|billable-hours|billing-periods)"
          component={Main}
        />
        <Route path="/" component={Page404} />
      </Switch>
      <Notification
        isOpen={snackbarOpen}
        error={error}
        message={message}
        closeSnackbar={closeSnackbar}
      />
    </IntlProvider>
  )
}

export default Sentry.withProfiler(App)
