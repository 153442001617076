import React from 'react'

import Snackbar from '@mui/material/Snackbar'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { Alert } from 'reactstrap'

const Notification = (props) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway')
      return
    props.closeSnackbar()
  }

  const Icon = !props.error ? CheckCircleIcon : ErrorIcon

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={props.isOpen}
      autoHideDuration={!props.error ? 1500 : 10000}
      onClose={() => handleClose()}
    >
      <div className="alert-notification-wrapper">
        <Alert
          className="alert-notification"
          color={!props.error ? 'success' : 'danger'}
          isOpen={props.isOpen}
          toggle={() => handleClose()}
          aria-describedby="client-snackbar"
        >
          <span id="message-id" className="notification__content">
            <Icon className="notification__icon" />
            {!props.error ? `${props.message === null ? 'Done!' : props.message}` : `${props.error}`}
          </span>
        </Alert>
      </div>
    </Snackbar>
  )
}

export default Notification
