import moment from "moment"

export const formatDate = (date) => {
  if (date !== null && typeof date !== 'string')
    return `${moment(date).format('YYYY-MM-DD')}`
}

export const getDateOptions = (date, withTime = false) => {
  const options = { day: '2-digit', month: 'short' }
  const isDate = (date) => {
    let timestamp = Date.parse(date)
    return !isNaN(timestamp)
  }
  if (!isDate(date)) return date
  date = new Date(date)
  if (date.getFullYear() !== new Date().getFullYear())
    options.year = 'numeric'
  if (withTime) {
    options.hour = 'numeric'
    options.minute = 'numeric'
    options.second = 'numeric'
    options.hour12 = false
  }
  return date.toLocaleString('en-EN', options)
}

export const addDate = (dates, date) => {
  let month = date.month
  let day = date.day
  if (date.month < 10)
    month = `0${month}`
  if (date.day < 10)
    day = `0${day}`
  dates.push(`${date.year}-${month}-${day}`)
}
