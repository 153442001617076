/* eslint-disable object-curly-newline */
import React from 'react'

import { InputAdornment, IconButton, Paper, Button, FormControl, InputLabel, Input } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { Redirect, useHistory } from 'react-router-dom'
import axios from 'axios'
import _ from 'lodash'

import { useDispatch, useSelector } from 'react-redux'
import { loadProfile } from '@store/actions/global'

import { Auth } from '@utils/helpers/auth'

import { setFormControlConfig } from './config/formControlConfig'

export const LoginForm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const saveLocation = useSelector((state) => state.global.saveLocation, _.isEqual)

  const [login, setLogin] = React.useState({ username: '', password: '' })
  const [errorText, setErrorText] = React.useState(null)
  const [showPassword, setShowPassword] = React.useState(false)

  const endAdornment = React.useMemo(() => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="Toggle password visibility"
          onClick={() => setShowPassword(!showPassword)}
          size="large">
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    )
  }, [showPassword])

  const loginHandler = async () => {
    await axios
      .post('/api/auth', { username: login.username, password: login.password }, { headers: Auth.withCsrf({}) })
      .then((result) => {
        if (result.status === 200) {
          Auth.saveCredentials(login.username, result.data['token'])
          dispatch(loadProfile()).then(() => history.push(saveLocation))
        }
      })
      .catch((error) => {
        let error_text = ''
        if (error.response) {
          error_text = error.response.statusText + '. ' + Object.values(error.response.data)
          Object.keys(error.response.data).forEach((key) => {
            if (['username', 'password'].includes(key))
              error_text = key + ' - ' + error.response.data[key]
          })
          if (error.response.status === 500)
            error_text = error.response.statusText + '. Something is wrong with server'
        }
        setErrorText(error_text)
      })
  }

  const handleChange = (event, formInput) => {
    setLogin((prevState) => ({
      username: formInput === 'username' ? event.target.value : prevState.username,
      password: formInput === 'password' ? event.target.value : prevState.password
    })
    )
  }

  if (Auth.isLogged())
    return <Redirect to={saveLocation} />

  const formControlConfig = setFormControlConfig(login, errorText, endAdornment, showPassword ? 'text' : 'password')

  return (
    <div className="login-main">
      <form
        onSubmit={(e) => {
          props.onSubmit ? props.onSubmit() : loginHandler()
          e.preventDefault()
        }}
      >
        <Paper className="login-wrapper">
          {formControlConfig.map((item, index) => {
            return (
              <div className="form-control" key={`${item.input.form}_${index}`}>
                <FormControl className="form-element" aria-describedby={item.formControl}>
                  <InputLabel htmlFor={item.inputLabel.htmlFor}>
                    {item.inputLabel.text}
                  </InputLabel>
                  <Input
                    data-testid={item.test.id}
                    id={item.input.id}
                    type={item.input.type}
                    defaultValue={item.input.value}
                    onChange={(event) => handleChange(event, item.input.form)}
                    endAdornment={item.input.endAdornment}
                  />
                </FormControl>
              </div>
            )
          })}
          <div style={{ color: '#FF1F1F', fontSize: '85%' }} data-testid="errorText">
            {errorText}
          </div>
          <Button variant="contained" color="primary" type="submit">
            Log in
          </Button>
        </Paper>
      </form>
    </div>
  )
}
