import Cookies from 'js-cookie'
import { tryParseJSONgroups } from '../util'

export class Auth {
  static isLogged() {
    return Auth.getUsername() !== null
  }

  static getUsername() {
    return localStorage.getItem('username')
  }

  static isViewer(groups) {
    const userGroups = groups || tryParseJSONgroups(localStorage.getItem('groups'))
    return userGroups && userGroups.includes('viewers')
  }

  static isHeadHunter(groups) {
    const userGroups =
      groups || tryParseJSONgroups(localStorage.getItem('groups'))
    return userGroups && userGroups.includes('headhunters')
  }

  static isUnderprivileged(groups) {
    return this.isViewer(groups) || this.isHeadHunter(groups)
  }

  static saveCredentials(username, token) {
    localStorage.setItem('username', username)
    localStorage.setItem('token', token)
  }

  static withAuth(headers) {
    headers['Authorization'] = 'Token ' + localStorage.getItem('token')
    return headers
  }

  static withCsrf(headers) {
    headers['X-CSRFToken'] = Cookies.get('csrftoken')
    return headers
  }

  static logout(history) {
    localStorage.removeItem('username')
    localStorage.removeItem('token')
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('userId')
    localStorage.removeItem('groups')
    history.push('/login')
  }
}
