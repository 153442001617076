import React from 'react'

import { DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'

export const DropdownLink = (props) => {

  return (
    <DropdownItem
      disabled={props.isUrlSelected(props.permissionLink.link)}
      tag={Link}
      active={props.isUrlSelected(props.permissionLink.link)}
      to={`/${props.permissionLink.link}`}
      onClick={() => props.handleLinkClick(props.permissionLink)}
    >
      {props.permissionLink.label}
    </DropdownItem>
  )
}
