import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import * as Sentry from '@sentry/react'

import { rootReducer } from './reducers'
import { loadProfileIfNeeded } from './actions/global'

const sentryReduxEnhancer = Sentry.createReduxEnhancer()

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk), sentryReduxEnhancer)
)

store.dispatch(loadProfileIfNeeded())
