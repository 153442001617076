import React from 'react'

import { useSelector } from 'react-redux'

import permissions from '@utils/helpers/permissions'

import { RenderLink } from './main/components/RenderLink'

const Page404 = () => {
  const groups = useSelector((state) => state.global.groups)

  const availableLinks = permissions.filter(
    (permissionLink) => permissionLink.hasPermission(groups) &&
      (permissionLink.label === "Reports" || permissionLink.label === "Report")
  )

  return (
    <div className="container">
      <p>404</p>
      <span>Wrong url. You can choose right way if need it:</span>
      <div className="links-container">
        {availableLinks.map((permissionLink, index) => (
          <RenderLink
            key={`${permissionLink.link}_${index}`}
            permissionLink={permissionLink}
          />
        ))}
      </div>
    </div>
  )
}

export default Page404
