import React from 'react'

export const MobileMenuIcon = (props) => {
  return (
    <React.Fragment>
      <svg
        className="header-menu-button__main"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 9 17"
      >
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg>
      <svg
        className={
          props.isMenuShowed
            ? 'header-menu-button__sub header-menu-button__sub_showed'
            : 'header-menu-button__sub'
        }
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 17"
      >
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
    </React.Fragment>
  )
}
