import React from 'react'

import { Link, useHistory } from 'react-router-dom'

export const SelectedLabel = (props) => {
  const history = useHistory()

  return (
    <Link
      onTouchEnd={() => history.push(`/${props.isFor === 'employee' ? 'employees' : 'projects'}/${props.entry['id']}`)}
      style={{ padding: '10px', margin: '0px' }}
      to={`/${props.isFor === 'employee' ? 'employees' : 'projects'}/${props.entry['id']}`}
    >
      {props.entry['name'] || props.entry['full_name']}
    </Link>
  )
}
