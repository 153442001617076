import * as actionTypes from '../actions/actionTypes'

const initialState = { milestones: [] }

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_MILESTONES_SUCCESS:
      return { ...state, milestones: action.milestones }
    case actionTypes.LOAD_MILESTONES_FAILURE:
      return { ...state, milestones: [] }
    default:
      return state
  }
}

export default reducer
