/* eslint-disable func-style */
import * as actionTypes from './actionTypes'

import axios from 'axios'

import { Auth } from '@utils/helpers/auth'
import { formatAxiosError, formatError } from '@utils/util'

export const globalInputFailure = (error) => {
  const error_text = formatError(error)
  return { type: actionTypes.GLOBAL_INPUT_FAILURE, error: error_text }
}

export const saveLocation = (path) => {
  return (dispatch) => dispatch({ type: actionTypes.SAVE_LOCATION, path: path })
}

export const loadProfile = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.GLOBAL_LOAD_REQUEST })
    return await axios
      .get('/api/profile', { headers: Auth.withAuth({}) })
      .then((response) => {
        const expirationDate = new Date(
          new Date().getTime() + (24 * 60 * 60 * 1000)
        )
        localStorage.setItem('groups', JSON.stringify(response.data.groups))
        localStorage.setItem('userId', response.data.id)
        localStorage.setItem('expirationDate', expirationDate)
        dispatch({
          type: actionTypes.LOAD_PROFILE_SUCCESS,
          groups: JSON.stringify(response.data.groups),
          userId: response.data.id
        })
        dispatch({ type: actionTypes.GLOBAL_LOAD_SUCCESS })
      })
      .catch((error) => {
        dispatch({ type: actionTypes.LOAD_PROFILE_FAILURE })
        dispatch({
          type: actionTypes.GLOBAL_LOAD_FAILURE,
          error: formatAxiosError(error),
          errorCode: error.response && error.response.status
        })
      })
  }
}

const shouldLoadProfile = (state) => {
  if (!localStorage.getItem('token'))
    return false
  const expirationDate = new Date(localStorage.getItem('expirationDate'))
  if (!state.global.groups || state.global.groups.length === 0 || !state.global.userId)
    return true
  if (expirationDate <= new Date())
    return true
}

export const loadProfileIfNeeded = () => {
  return (dispatch, getState) => {
    if (shouldLoadProfile(getState()))
      return dispatch(loadProfile())
  }
}

export const globalShowNotification = (message = '') => {
  return { type: actionTypes.GLOBAL_SHOW_NOTIFICATION, message: message }
}
